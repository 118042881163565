export const initUiInteractions = () => {
    // product details slider active
    $('.product-large-slider').slick({
        fade: true,
        arrows: true,
        speed: 1000,
        asNavFor: '.pro-nav',
    });

    // product details slider nav active
    $('.pro-nav').slick({
        slidesToShow: 3,
        asNavFor: '.product-large-slider',
        centerMode: true,
        speed: 1000,
        centerPadding: 0,
        focusOnSelect: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    });

    $('.products-grid-slider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 470,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    $(document).ready(() => {
        const content = document.getElementById('description-content');
        const readMore = document.getElementById('read-more');
        const iconMore = document.getElementById('icon-more');

        // zobrazíme jen, když je overflow
        if (content !== null && readMore !== null && iconMore !== null) {
            readMore.style.display = content.scrollHeight > content.offsetHeight ? 'block' : 'none';

            $(document).on('click', '#read-more', function () {
                content.classList.toggle('description-content__hidden');
                iconMore.classList.toggle('img-rotate');
                $(this)
                    .find('.text')
                    .text(
                        content.classList.contains('description-content__hidden')
                            ? $(this).data('showAll')
                            : $(this).data('showLess')
                    );
            });
        }

        $(document).on('click', '.read-more-products', function () {
            const moreLessButton = $('.invisible-content').is(':visible') ? 'Ukázat další' : 'Skrýt další';
            $(this).toggleClass('show-more');
            $(this).find('.text').text(moreLessButton);
            $(this).parent('.large-content').find('.invisible-content').toggle();
        });

        $(document).on('click', '.read-more-filters', function () {
            const moreLessButton = $('.invisible-content').is(':visible') ? 'Ukázat vše' : 'Skrýt vše';
            $(this).toggleClass('show-more');
            $(this).find('.text').text(moreLessButton);
            $(this).parent('.large-content').find('.invisible-content').toggle();
        });

        $('input.company_checkbox').click(() => {
            if ($('input.company_checkbox').is(':checked')) {
                $('#companydata').show('fast');
            } else {
                $('#companydata').hide('fast');
            }
        });

        $('input.delivery_checkbox').click(() => {
            if ($('input.delivery_checkbox').is(':checked')) {
                $('#deliverydata').show('fast');
            } else {
                $('#deliverydata').hide('fast');
            }
        });

        const togglePassword = document.querySelector('#togglePassword');
        const password = document.querySelector('#password');

        if (togglePassword) {
            togglePassword.addEventListener('click', function () {
                // toggle the type attribute
                const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
                password.setAttribute('type', type);
                // toggle the eye / eye slash icon
                this.classList.toggle('shown');
            });
        }

        const togglePassword2 = document.querySelector('#togglePassword2');
        const password2 = document.querySelector('#password2');

        if (togglePassword2) {
            togglePassword2.addEventListener('click', function () {
                // toggle the type attribute
                const type = password2.getAttribute('type') === 'password' ? 'text' : 'password';
                password2.setAttribute('type', type);
                // toggle the eye / eye slash icon
                this.classList.toggle('shown');
            });
        }

        $(document).on('click', '.show-all-filters', function () {
            $(this).toggleClass('show-more');
            $('#filter-toggle').toggleClass('d-none');
            $('#mobile-filter-show').toggleClass('d-none');
            $('#mobile-filter-hide').toggleClass('d-none');
        });

        $('#filterscheckboxes').each(() => {
            const $categoryCheckbox = $('.filter-checkbox');
            $categoryCheckbox.on('change', function () {
                if ($(this).is(':checked')) {
                    $('#categoryactivefilters').append(
                        '<div id="' +
                            $(this).attr('name') +
                            '" class="chosen-filter">' +
                            $(this).attr('title') +
                            '</div>'
                    );
                } else {
                    $('#categoryactivefilters')
                        .find('div#' + $(this).attr('name'))
                        .remove();
                }
            });
        });

        $(document).on('click', '#categoryactivefilters .chosen-filter', function () {
            $('#filterscheckboxes')
                .find('#' + $(this).attr('id'))
                .each(function () {
                    this.prop('checked', false);
                });
            $(this).remove();
        });
    });

    if (window.innerWidth > 1281) {
        document.querySelectorAll('.navbar .nav-item').forEach((everyitem) => {
            everyitem.addEventListener('mouseenter', function () {
                const el_link = this.querySelector('a.dropdown-toggle');
                const el_overlay = document.createElement('span');
                const headerHeight = document.getElementById('header').offsetHeight;

                el_overlay.className = 'screen-darken';
                el_overlay.style.marginTop = headerHeight + 'px';

                if (el_link != null) {
                    const nextEl = el_link.nextElementSibling;
                    el_link.classList.add('show');
                    nextEl.classList.add('show');

                    document.body.appendChild(el_overlay);
                }
            });

            everyitem.addEventListener('click', function (e) {
                const el_link = this.querySelector('a.dropdown-toggle');

                if (el_link != null) {
                    const nextEl = el_link.nextElementSibling;
                    el_link.classList.add('show');
                    nextEl.classList.add('show');
                    e.stopPropagation();
                }
            });

            everyitem.addEventListener('mouseleave', function () {
                const el_link = this.querySelector('a.dropdown-toggle');

                if (el_link != null) {
                    const nextEl = el_link.nextElementSibling;
                    el_link.classList.remove('show');
                    nextEl.classList.remove('show');
                }

                document.body.removeChild(document.querySelector('.screen-darken'));
            });
        });
    }

    if (window.innerWidth < 1281) {
        console.log('<1200');

        document.querySelectorAll('.navbar .dropdown').forEach((everyitem) => {
            everyitem.addEventListener('click', function (e) {
                const el_link = this.querySelector('a.dropdown-toggle');

                console.log('aria-expanded?' + el_link.getAttribute('aria-expanded'));

                if (el_link.getAttribute('aria-expanded') == 'false') {
                    const screenDarken = document.querySelector('.screen-darken');
                    if (screenDarken) {
                        screenDarken.parentNode.removeChild(screenDarken);
                    }
                }

                if (el_link.getAttribute('aria-expanded') == 'true') {
                    const screenDarken = document.querySelector('.screen-darken');
                    if (!screenDarken) {
                        const headerHeight = document.getElementById('header').offsetHeight;
                        const screenDarken = document.createElement('span');
                        screenDarken.className = 'screen-darken';
                        screenDarken.style.marginTop = headerHeight + 'px';
                        document.body.appendChild(screenDarken);
                    }
                }

                e.stopPropagation();
            });

            everyitem.addEventListener('mouseleave', function () {
                console.log('mouseleave');
                const el_link = this.querySelector('a.dropdown-toggle');

                if (el_link != null) {
                    const nextEl = el_link.nextElementSibling;
                    el_link.classList.remove('show');
                    nextEl.classList.remove('show');
                }

                const screenDarken = document.querySelector('.screen-darken');
                if (screenDarken) {
                    screenDarken.parentNode.removeChild(screenDarken);
                }
            });
        });
    }
};
